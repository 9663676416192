import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import {AuthorizationService} from '@unleashed/services/account';
import {switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JustifiApiService extends ServiceBase {

  subnav = 'invoices';

  constructor(@Inject(APP_OPTIONS) appOptions: AppOptions, private apiService: ApiService, private auth: AuthorizationService) {
    super(appOptions.unleashedApiUrl);
  }

  getToken(brandSlug: string, locationSlug: string): Observable<string> {
    return this.auth.getAccessToken().pipe(
      switchMap(token =>
        this.apiService.post<string>(this.buildUrl(`tokens`) + `/${brandSlug}/${locationSlug}`, null,
          {headers: {Authorization: `Bearer ${token}`}}))
    );
  }
}
