import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {concatMap, map, mergeMap, switchMap, take, tap} from 'rxjs/operators';
import {cardPaymentMethodPayload, Dropin} from 'braintree-web-drop-in';
import {BusyService} from '@unleashed/services/common';
import {APP_OPTIONS, AppOptions, BRAND_OPTIONS, BrandOptions} from '@unleashed/common/config';
import {BookingApiService, InvoiceApiService, PaymentsApiService} from '@unleashed/api/booking';
import {User} from '@unleashed/models/account';
import {AuthorizationService} from '@unleashed/services/account';
import {Booking, NavigationSteps} from '@unleashed/models/booking';
import {BookingSessionService} from '@unleashed/services/booking';
import {Park} from '@unleashed/models/park';
import {StatesApiService} from '@unleashed/api/address';
import {State} from '@unleashed/models/address';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {selectOptionValidator, zipCodeFormatValidator} from '@unleashed/common/validators';
import {BookingInvoiceApiService} from '@unleashed/api/booking/booking-invoice-api.service';
import {v4 as uuidv4} from 'uuid';
import {Payment} from '@unleashed/models/booking/payment';
import {VaultedCardApiService} from '@unleashed/api/account';
import {PaymentMethod} from '@unleashed/models/account/PaymentMethod';
import {BillingData} from '@unleashed/models/account/BillingData';
import {PaymentOrigins} from '@unleashed/models/booking/paymentOrigins';
import {JustifiApiService} from '@unleashed/api/justifi/justifi-api.service';

@Component({
  selector: 'ua-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  booking?: Booking;
  dropinInstance?: Dropin;
  paymentInitialized = false;
  user?: User;
  accessToken = '';
  documentsAcknowledged = false;
  depositAcknowledged = false;
  weatherAcknowledged = false;
  eSignatureLink: string;
  privacyLink: string;
  termsLink: string;
  uaAdmin: string;
  braintreeError?: string;
  showSpecialEventText: boolean;
  brandId: number;
  park?: Observable<Park | null | undefined>;
  paymentProcessorVersion?: number;
  subAccountId?: string;
  canadaSubAccountId?: string;
  justifiElem?: ElementRef;
  isAvs: boolean;
  states: State[] = [];
  form: FormGroup;
  formDirty = false;
  justifiError = false;
  parkId?: string;
  useDefaultCard = true;
  addNewCard = false;
  defaultCard?: PaymentMethod;
  defaultCardValue?: string;
  filteredCards?: PaymentMethod[] = [];
  currencyCode?: string;
  country?: string;
  webComponentAuthToken = '';

  constructor(
    private sessionService: BookingSessionService,
    private authService: AuthorizationService,
    private paymentsApi: PaymentsApiService,
    private busyService: BusyService,
    private bookingApi: BookingApiService,
    private invoiceApi: InvoiceApiService,
    private stateService: StatesApiService,
    private bookingInvoiceApi: BookingInvoiceApiService,
    private vaultedCardApi: VaultedCardApiService,
    private justifiApiService: JustifiApiService,
    fb: FormBuilder,
    @Inject(APP_OPTIONS) appOptions: AppOptions,
    @Inject(BRAND_OPTIONS) private brandOptions: BrandOptions
  ) {
    this.uaAdmin = appOptions.uaAdminUrl;
    this.eSignatureLink = brandOptions.eSignatureAgreementLink;
    this.privacyLink = brandOptions.privacyLink;
    this.termsLink = brandOptions.termsLink;
    this.showSpecialEventText = brandOptions.showSpecialEventText;
    this.brandId = brandOptions.brandId;
    this.isAvs = brandOptions.isAvs;
    this.form = fb.group({
      zip: new FormControl('', [Validators.required, zipCodeFormatValidator()])
    });
  }

  ngOnInit(): void {
    this.busyService.set('vaultedCards', true);
    this.sessionService.getConfig().pipe(
      switchMap(config => {
        this.subAccountId = config.justifiSubAccountId;
        this.canadaSubAccountId = config.justifiCanadaSubAccountId;
        return this.sessionService.getSession();
      }),
      mergeMap(session => {
        this.paymentProcessorVersion = (this.subAccountId || this.canadaSubAccountId) ? session?.park?.paymentProcessorVersion : 1;
        if (this.paymentProcessorVersion === 2 && (this.subAccountId || this.canadaSubAccountId)) {
          return this.justifiApiService.getToken(this.brandOptions.brandSlug, session?.park?.urlSlug ?? '')
            .pipe(
              tap(token => this.webComponentAuthToken = token),
              map(_ => session)
            );
        } else {
          return of(session);
        }
      })
    ).subscribe(session => {
      this.parkId = session?.park?.id;
      this.currencyCode = session.park?.currencyCode;
      this.country = session.park?.address?.country?.toLowerCase().trim() ?? 'us';
      this.vaultedCardApi.getVaultedCards(this.paymentProcessorVersion, this.country === 'ca' ? this.canadaSubAccountId : this.subAccountId)
        .subscribe(cards =>   {
          this.defaultCard = cards?.find(c => c.isDefault);
          this.filteredCards = cards?.filter(cc => !cc.isExpired);
          if (!this.defaultCard){
            this.useDefaultCard = false;
            this.addNewCard = true;
          }
          if (this.defaultCard && this.useDefaultCard){
            this.paymentInitialized = true;
            this.defaultCardValue = this.defaultCard?.id;
          }
          this.busyService.set('vaultedCards', false);
        });

    });
    this.sessionService.getBooking()
      .pipe(
        take(1),
        concatMap(booking => {
          if (!booking.cookieId) {
            this.sessionService.navigateToStep(NavigationSteps.Confirmation);
          }
          this.booking = booking;
          return this.authService.getUser();
        }),
        concatMap(user => {
          this.user = user;
          if (!user.loggedIn) {
            this.sessionService.navigateToStep(NavigationSteps.Review);
          } else if (!user.address?.zipCode && !user.impersonated) {
            this.sessionService.navigateToStep(NavigationSteps.Review);
          }
          if (this.isAvs) {
            this.form.addControl('fullName', new FormControl('', [Validators.required]));
            this.form.addControl('streetAddress', new FormControl('', [Validators.required]));
            this.form.addControl('city', new FormControl('', [Validators.required]));
            this.form.addControl('state', new FormControl(-1, [Validators.required, selectOptionValidator([-1])]));
          }
          return this.authService.getAccessToken();
        }))
      .subscribe(accessToken => this.accessToken = accessToken);

    this.busyService.set('states', true);
    this.stateService.get()
      .subscribe(states => {
        this.states = states;
        this.busyService.set('states', false);
      });
  }

  back(): void {
    if (!this.booking) {
      return;
    }
    this.booking.step = 6;
    this.sessionService.navigateToStep(NavigationSteps.Review);
  }

  async payNow(): Promise<void> {
    if (this.paymentProcessorVersion === 1) {
      this.payBrainTree();
    } else {
      await this.payJustifi();
    }
  }
  async payJustifi(): Promise<void> {
    this.justifiError = false;
    this.busyService.set('pay', true);
    this.formDirty = true;
    if (this.addNewCard){
      const validation = await this.justifiElem?.nativeElement.validate();
      if (!validation.isValid || !this.form.valid) {
        this.busyService.set('pay', false);
        return;
      }
    }

    if (this.booking) {
      this.booking.idempotencyKey = uuidv4();
    }

    let billingData: BillingData = {
      address_postal_code: this.form.get('zip')?.value,
      name: this.form.get('fullName')?.value,
      email: `${this.user?.accountId}@commandcenter.unleashedbrands.com`,
    };
    if (this.isAvs){
      billingData = {
        ...billingData,
        address_line1: this.form.get('streetAddress')?.value,
        address_city: this.form.get('city')?.value,
        address_state: this.form.get('state')?.value,
      };
    }

    const handleError = () => {
      this.justifiError = true;
      this.busyService.set('pay', false);
    };

    let token;
    if (this.addNewCard){
      token = await this.justifiElem?.nativeElement.tokenize(
        this.webComponentAuthToken,
        billingData,
        this.country === 'ca' ? this.canadaSubAccountId : this.subAccountId);
      if (token?.error) {
        handleError();
        throw new Error(JSON.stringify(token.error));
      }
    }
    const payload: Payment = {
      idempotencyKey: this.booking?.idempotencyKey,
      token: this.addNewCard ? token.id : this.defaultCardValue
    };
    const paymentOriginId = !this.user?.impersonated ? PaymentOrigins.Ecommerce : PaymentOrigins.Admin;

    let processDeposit$: Observable<string>;

    if (!this.booking?.isSpecialEvent) {
      processDeposit$ = this.bookingInvoiceApi.processCartDeposit(
        paymentOriginId, this.brandId, this.accessToken, payload, this.parkId, this.booking?.cookieId);
    } else {
      processDeposit$ = this.bookingInvoiceApi.processSpecialEventDeposit(
        paymentOriginId, this.brandId, this.accessToken, payload, this.parkId, this.booking?.cookieId);
    }

    if (this.user?.impersonated) {
      processDeposit$
        .pipe(
          concatMap(confirmation => this.bookingInvoiceApi.getInvoice(confirmation, this.accessToken, this.brandId, this.parkId))
        )
        .subscribe(invoice => {
          this.authService.logout();
          if (this.booking?.cookieId) {
            this.bookingApi.deleteCart(this.booking.cookieId);
          }
          if (invoice.isSpecialEvent) {
            document.location.href = `${this.uaAdmin}/parties/special-events/${invoice.bookingId}`;
          } else {
            document.location.href = `${this.uaAdmin}/parties/booking/${invoice.bookingId}?parkId=${invoice.park.id}`;
          }
          this.busyService.set('pay', false);
        }, handleError);
    } else {
      processDeposit$
        .subscribe(
          r => {
            this.sessionService.setBookingCookie(r);
            this.sessionService.removeCart();
            if (this.booking?.cookieId) {
              this.bookingApi.deleteCart(this.booking.cookieId);
            }
            this.sessionService.navigateToStep(NavigationSteps.Confirmation);
            this.busyService.set('pay', false);
          },
          handleError);
    }
  }
  payBrainTree(): void{
    this.busyService.set('pay', true);
    this.dropinInstance?.requestPaymentMethod({
      threeDSecure: {
        amount: this.booking?.depositAmount?.toString() ?? '',
        mobilePhoneNumber: this.user?.phoneNumber,
        email: this.user?.emailAddress,
        billingAddress: {
          givenName: this.user?.firstName,
          surname: this.user?.lastName,
          streetAddress: this.user?.address?.streetAddress,
          locality: this.user?.address?.city,
          region: this.user?.address?.state,
          postalCode: this.user?.address?.zipCode,
          countryCodeAlpha2: this.user?.address?.country.trim()
        }
      }
    }, (error, payload) => {
      if (error) {
        this.busyService.set('pay', false);
        throw error;
      }
      if (!this.booking) {
        return;
      }

      const card = payload as cardPaymentMethodPayload;
      if (!card) {
        // unsupported payment method?
        return;
      }

      let processDeposit$: Observable<string>;

      if (this.booking.isSpecialEvent) {
        processDeposit$ = this.paymentsApi.processBookingDeposit(this.booking.cookieId ?? '', card, this.accessToken);
      } else {
        processDeposit$ = this.paymentsApi.processCartDeposit(this.booking.cookieId ?? '', card, this.accessToken);
      }

      const handleError = (e: { error: { message: string } }) => {
        this.busyService.set('pay', false);
        this.braintreeError = e.error.message.split('\n').length > 1
          ? e.error.message.split('\n')[0]
          : e.error.message;
        this.dropinInstance?.clearSelectedPaymentMethod();
      };

      if (this.user?.impersonated) {
        processDeposit$
          .pipe(
            concatMap(confirmation => this.invoiceApi.getInvoice(confirmation, this.accessToken))
          )
          .subscribe(invoice => {
            this.authService.logout();
            if (this.booking?.cookieId) {
              this.bookingApi.deleteCart(this.booking.cookieId);
            }
            if (invoice.isSpecialEvent) {
              document.location.href = `${this.uaAdmin}/parties/special-events/${invoice.bookingId}`;
            } else {
              document.location.href = `${this.uaAdmin}/parties/booking/${invoice.bookingId}?parkId=${invoice.park.id}`;
            }
          }, handleError);
      } else {
        processDeposit$
          .subscribe(
            r => {
              this.sessionService.setBookingCookie(r);
              this.sessionService.removeCart();
              if (this.booking?.cookieId) {
                this.bookingApi.deleteCart(this.booking.cookieId);
              }
              this.busyService.set('pay', false);
              this.sessionService.navigateToStep(NavigationSteps.Confirmation);
            },
            handleError);
      }
    });
  }

  setDropin(dropin: Dropin): void {
    this.dropinInstance = dropin;
    this.paymentInitialized = true;
  }

  setPaymentInitialized(elem: ElementRef): void {
    this.paymentInitialized = true;
    this.justifiElem = elem;
  }

  get acknowledged(): boolean {
    return (this.documentsAcknowledged || (this.user?.impersonated ?? false)) && this.depositAcknowledged && this.weatherAcknowledged;
  }

  addPaymentMethodClick(): void {
    this.addNewCard = true;
    this.useDefaultCard = false;
  }
  onDefaultChanged(): void {
    this.addNewCard = false;
    if (this.useDefaultCard){
      this.defaultCardValue = this.defaultCard?.id;
    }
  }
  useAccountAddress(): void {
    this.form.patchValue({
      fullName:  this.user?.firstName && this.user?.lastName ? `${this.user?.firstName} ${this.user?.lastName}` : '',
      streetAddress: this.user?.address?.streetAddress ?? '',
      city: this.user?.address?.city ?? '',
      state: this.user?.address?.state ?? -1,
      zip: this.user?.address?.zipCode ?? ''
    });
  }
}
