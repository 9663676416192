import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import { take } from 'rxjs/operators';
import { faInfoCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'bootstrap';
import { BusyService } from '@unleashed/services/common';
import { Booking, Hangout, NavigationSteps } from '@unleashed/models/booking';
import { HangoutApiService } from '@unleashed/api/booking';
import { BookingSessionService } from '@unleashed/services/booking';
import { GoogleTagManagerService } from "angular-google-tag-manager";

@Component({
  selector: 'ua-hangouts',
  templateUrl: './hangouts.component.html',
  styleUrls: ['./hangouts.component.scss']
})
export class HangoutsComponent implements OnInit, OnDestroy {

  faInfoCircle = faInfoCircle;
  faCheck = faCheck;

  booking?: Booking;
  hangoutsStore: BehaviorSubject<Hangout[]> = new BehaviorSubject<Hangout[]>([]);
  hangouts: Hangout[] = [];
  selectionChanged = false;
  hangoutSelected = false;
  amenitiesModal?: Modal;
  amenities: string[] = [];
  hangoutsLoaded = false;
  error?: number;
  subscriptions = new Subscription();

  constructor(
    private sessionService: BookingSessionService,
    private hangoutApiService: HangoutApiService,
    private busyService: BusyService,
    private gtmService: GoogleTagManagerService
  ) {
  }

  ngOnInit(): void {
    const element = document.getElementById('amenitiesModal');
    if (element) {
      this.amenitiesModal = new Modal(element);
    }

    this.subscriptions.add(
      this.sessionService.getBooking()
        .pipe(take(1))
        .subscribe(booking => {
          this.booking = booking;
          this.loadHangouts(booking);
          this.sessionService.setSubtotal(booking.scheduleDetailId ? this.sessionService.getSubtotal(booking) : (booking.minSubtotal ?? 0));
        }, err => this.error = err.status)
    );

    this.subscriptions.add(this.hangoutsStore.subscribe(hangouts => this.hangouts = hangouts));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadHangouts(booking: Booking): void {
    this.hangoutApiService.getHangouts(booking)
      .subscribe(hangouts => {

        let selectedHangoutIndex = hangouts
          .findIndex(h => h.resourceTypeId === booking.selectedResourceTypeId
            && h.price === booking.selectedResourcePrice
            && h.available > 0);

        const minPrice = Math.min(...hangouts.map(h => h.price));

        if (selectedHangoutIndex === -1) {
          const available = hangouts.filter(h => h.available);
          if (available.length && available[available.length - 1].price === minPrice) {
            selectedHangoutIndex = hangouts.indexOf(available[available.length - 1]);
            this.selectionChanged = true;
          }
        }

        for (let i = 0; i < hangouts.length; i++) {
          if (i === selectedHangoutIndex) {
            hangouts[i].selected = true;
            hangouts[i].displayPrice = 0;
            this.hangoutSelected = true;
          } else {
            hangouts[i].displayPrice = selectedHangoutIndex >= 0
              ? hangouts[i].price - hangouts[selectedHangoutIndex].price
              : hangouts[i].price;
          }
        }
        this.hangoutsLoaded = true;
        this.hangoutsStore.next(hangouts);
      }, err => this.error = err.status);
  }

  toggleSelection(index: number): void {
    if (!this.booking) {
      return;
    }
    
    this.selectionChanged = true;
    this.booking.bookingItems = [];
    this.booking.participantSubtotal = undefined;
    this.booking.selectedResourcePrice = undefined;
    let subtotal = this.sessionService.getSubtotal(this.booking);
    for (let i = 0; i < this.hangouts.length; i++) {

      if (i === index) {
        continue;
      }
      this.hangouts[i].selected = false;
      if (!this.hangouts[index].selected) {
        this.hangouts[i].displayPrice = this.hangouts[i].price - this.hangouts[index].price;
      } else {
        this.hangouts[i].displayPrice = this.hangouts[i].price;
      }
    }

    this.hangouts[index].selected = !this.hangouts[index].selected;
    if (this.hangouts[index].selected) {
      subtotal += this.hangouts[index].price;
      this.hangouts[index].displayPrice = 0;
    } else {
      this.hangouts[index].displayPrice = this.hangouts[index].price;
    }
    if (this.booking) {
      this.sessionService.setSubtotal(subtotal);
    }

  }

  back(): void {
    if (!this.booking) {
      return;
    }
    this.booking.step = 3;
    this.saveAndNavigate(this.booking, NavigationSteps.TimeSlots);
  }

  next(): void {
    if (!this.booking) {
      return;
    }
    this.booking.step = 4;
    this.saveAndNavigate(this.booking, NavigationSteps.Attendees);
  }

  saveAndNavigate(booking: Booking, step: NavigationSteps): void {
    const selected = this.hangouts.find(t => t.selected);
  
    if (!this.selectionChanged || this.hangouts.find(h => h.available) === undefined) {
      this.sessionService.navigateToStep(step);
      return;
    }

    this.gtmService.pushTag({
      'event': 'hangoutSelectClicked',
      'selectedHangout':  selected?.name
    });

    this.busyService.set('hangouts', true);

    booking.selectedResourceTypeId = selected?.resourceTypeId;
    booking.selectedResourcePrice = selected?.price;
    booking.baseParticipantCount = undefined;
    booking.extraParticipantCount = undefined;
    booking.adultParticipantCount = undefined;
    booking.bookingItems = [];
    booking.adultBundleScheduleId = undefined;
    booking.extraBundleScheduleId = undefined;

    if (selected) {
      this.sessionService.confirmHangout(booking)
        .subscribe(
          b => {
            // Confirmation was not successful (concurrency conflict)
            this.busyService.set('hangouts', false);
            if (!b.scheduleDetailId) {
              this.hangoutsLoaded = false;
              this.hangoutSelected = false;
              this.loadHangouts(booking);
              return;
            }
            this.sessionService.navigateToStep(step);
          },
          err => {
            this.error = err.status;
            this.busyService.set('hangouts', false);
          });
    } else {
      this.sessionService.upsertCart(booking)
        .subscribe(
          () => {
            this.busyService.set('hangouts', false);
            this.sessionService.navigateToStep(step);
          },
          err => {
            this.error = err.status;
            this.busyService.set('hangouts', false);
          });
    }
  }

  showAmenitiesModal(hangout: Hangout): void {
    this.amenitiesModal?.show();
    this.amenities = hangout.amenities;
  }

}
