import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { APP_OPTIONS, AppOptions, BRAND_OPTIONS, BrandOptions } from '@unleashed/common/config';
import { BookingConfiguration, Booking, BookingSession, NavigationSteps, Timeslot } from '@unleashed/models/booking';
import { BookingApiService, BookingConfigurationApiService, HangoutApiService } from '@unleashed/api/booking';
import { AuthorizationService } from '@unleashed/services/account';
import { ParksApiService } from '@unleashed/api/park';
import { Park } from '@unleashed/models/park';

@Injectable({
  providedIn: 'root'
})
// FIXME this service is shared by multiple features (and the header) and should be extracted out (AppSessionService?)
export class BookingSessionService {

  brandId: number;
  sessionStore = new BehaviorSubject<BookingSession>({});
  configStore = new BehaviorSubject<BookingConfiguration>({
    minAdvanceDays: 2,
    maxAdvanceDays: 60,
    maxGuestsOfHonor: 2,
    spotWarningThreshold: 3,
    preferredTimes: [],
    threeDSecureEnabled: true,
    justifiSubAccountId: undefined,
    justifiCanadaSubAccountId: undefined,
  });
  parkStore = new BehaviorSubject<Park[]>([]);
  bookingStore: BehaviorSubject<Booking>;
  userWasLoggedInApplyingPromo?: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  bookingPromoModalTrigger?: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  bookingPromoMessage?: BehaviorSubject<string> = new BehaviorSubject<string>('');
  timeSlotsDate = new Date();
  timeSlotMap = new Map<number, Timeslot[]>();
  timeSlotStore = new BehaviorSubject<Timeslot[]>([]);
  cartCookieName: string;
  bookingCookieName: string;
  defaultBooking: Booking;
  parkId?: string;

  constructor(
    private router: Router,
    private bookingApi: BookingApiService,
    private parksApiService: ParksApiService,
    private configurationApiService: BookingConfigurationApiService,
    private hangoutApiService: HangoutApiService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private authService: AuthorizationService,
    @Inject(APP_OPTIONS) private appOptions: AppOptions,
    @Inject(BRAND_OPTIONS) private brandOptions: BrandOptions
  ) {
    this.brandId = brandOptions.brandId;
    this.cartCookieName = appOptions.cookieName;
    this.bookingCookieName = appOptions.bookingCookieName;
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    this.defaultBooking = {
      parkId: '',
      requiresHandicap: false,
      guestsOfHonor: [
        {
          firstName: '',
          lastName: '',
          dateOfBirth: today
        }
      ],
      contactFirstName: '',
      contactLastName: '',
      phoneNumber: '',
      preferredDate: today,
      preferredTimeId: 0,
      step: 1,
      isDefault: true,
      bookingItems: []
    };
    this.bookingStore = new BehaviorSubject<Booking>(this.defaultBooking);
  }

  initialize(): Observable<void> {
    const cookieId = this.cookieService.get(this.cartCookieName);
    return combineLatest([
      this.parksApiService.get(this.brandId),
      this.configurationApiService.get(),
      this.authService.initialize()
    ])
      .pipe(map(([parks, config]) => {
        this.configStore.next(config);
        this.parkStore.next(parks);
        if (cookieId) {
          this.bookingApi.getBooking(cookieId)
            .subscribe(
              booking => {
                this.setParkById(booking.parkId);
                this.setSubtotal(this.getSubtotal(booking));
                this.bookingStore.next(booking);
              },
              _ => {
                this.cookieService.delete(this.cartCookieName, '/');
              });
        }
      }));
  }

  upsertCart(booking: Booking): Observable<Booking> {
    return this.bookingApi.createCart(booking)
      .pipe(tap(b => {
        if (b) {
          this.setSubtotal(this.getSubtotal(b));
          if (this.cookieService.get(this.cartCookieName) !== b.cookieId) {
            this.cookieService.set(this.cartCookieName, b.cookieId ?? '', 1, '/');
          }

          if (this.timeSlotsDate !== b.selectedDate) {
            this.resetTimeSlots();
          }

          this.bookingStore.next(b);
        }
      }));
  }

  expireCart(booking: Booking): void {
    if (booking.timeBegan) {
      booking.timeBegan.setHours(booking.timeBegan.getHours() - 2);
    }
      booking.cartReleased = new Date()
      this.bookingApi.createCart(booking)
        .subscribe();
  }

  getSubtotal(booking: Booking): number {
    let subtotal = (booking.timeSlotSubtotal ?? 0) + (booking.participantSubtotal ?? 0) + (booking.selectedResourcePrice ?? 0);
    booking.bookingItems?.forEach(item => subtotal += (item.price * item.quantity));
    if (subtotal === 0 && !booking.selectedResourcePrice) {
      return booking.minSubtotal ?? 0;
    }
    return subtotal;
  }

  setSubtotal(subtotal: number): void {
    this.sessionStore.next({...this.sessionStore.value, subtotal});
  }

  removeCart(): void {
    this.expireCart(this.bookingStore.value);
    this.cookieService.delete(this.cartCookieName, '/');
    this.setSubtotal(0);
    this.bookingStore.next(this.defaultBooking);
  }

  initializeBooking(cookieId: string, step: number): Observable<Booking> {
    this.cookieService.set(this.cartCookieName, cookieId, 1, '/');
    return this.bookingApi.getBooking(cookieId)
      .pipe(tap(booking => {
        this.setParkById(booking.parkId);
        this.setSubtotal(this.getSubtotal(booking));
        booking.step = step;
        this.bookingStore.next(booking);
      }));
  }

  setBookingCookie(id: string): void {
    if (this.cookieService.get(this.bookingCookieName) !== id) {
      this.cookieService.set(this.bookingCookieName, id, 1, '/');
    }
    if (this.sessionStore.value.bookingId !== id) {
      this.sessionStore.next({...this.sessionStore.value, bookingId: id});
    }
  }

  removeBooking(): void {
    this.sessionStore.next({...this.sessionStore.value, bookingId: undefined});
    this.cookieService.delete(this.bookingCookieName, '/');
  }

  removeSessionCart(): void {
    this.cookieService.delete(this.cartCookieName, '/');
    this.bookingStore.next(this.defaultBooking);
    this.setSubtotal(0);
  }

  setParkById(parkId: string): void {
    const park = this.parkStore.value.find(p => p.id === parkId);
    this.setPark(park);
  }

  setParkBySlug(slug?: string): void {
    const park = this.parkStore.value.find(p => p.urlSlug === slug);
    this.setPark(park);
  }

  setPark(park?: Park): void {
    if (!park || !park.bookingDepositAmount) {
      this.navigateToStep(NavigationSteps.Parks);
    }

    this.resetTimeSlots();

    if (park && this.bookingStore.value.parkId && park.id !== this.bookingStore.value.parkId) {
      this.removeCart();
    }
    if (park && this.parkId && park.id !== this.parkId) {
      this.removeBooking();
    }

    const session = this.sessionStore.value;
    session.park = park;

    this.parkId = park?.id;
    this.sessionStore.next(session);
  }

  resetTimeSlots(): void {
    this.timeSlotMap.clear();
    this.timeSlotStore.next([]);
  }

  getBooking(): Observable<Booking> {
    return this.bookingStore.asObservable().pipe(shareReplay(1));
  }

  getSession(): Observable<BookingSession> {
    return this.sessionStore.asObservable().pipe(shareReplay(1));
  }

  getConfig(): Observable<BookingConfiguration> {
    return this.configStore.asObservable().pipe(shareReplay(1));
  }

  navigateToLogin(redirect?: NavigationSteps): void {
    this.router.navigate(
      ['account', 'login'],
      {
        queryParams:
          {
            park: this.sessionStore.value.park?.urlSlug,
            step: redirect
          }
      }).then();
  }

  navigateToSignUp(redirect?: NavigationSteps, redirectOnExists?: NavigationSteps): void {
    this.router.navigate(
      ['account', 'login'],
      {
        queryParams:
          {
            park: this.sessionStore.value.park?.urlSlug,
            step: redirect,
            existsStep: redirectOnExists,
            signup: true
          }
      }).then();
  }

  navigateToAccountInfo(redirect?: NavigationSteps, edit?: boolean): void {
    this.router.navigate(
      ['account', 'info'],
      {
        queryParams:
          {
            park: this.sessionStore.value.park?.urlSlug,
            step: redirect,
            edit
          }
      }).then();
  }

  navigateToReservations(redirect?: NavigationSteps, edit?: boolean): void {
    this.router.navigate(
      ['account', 'reservations'],
      {
        queryParams:
          {
            park: this.sessionStore.value.park?.urlSlug,
            step: redirect,
            edit
          }
      }).then();
  }

  navigateToStep(step: NavigationSteps, isWindowReload: boolean = false): void {
    const slug = this.sessionStore.value.park?.urlSlug;

    if (!slug || step === NavigationSteps.Parks) {
      this.router.navigate(['parks']).then();
      return;
    }

    const route = this.route?.parent?.snapshot.url[2].path;
    if (route === step) {
      return;
    }
    this.sessionStore.next({...this.sessionStore.value, step});
    if (isWindowReload) {
      window.location.href = `${window.location.origin}/parks/${this.sessionStore.value.park?.urlSlug}/${step}`;
    } else {
      this.router.navigate(['parks', this.sessionStore.value.park?.urlSlug, step], { queryParams: this.getQueryParams(step)})
        .then();
    }
  }

  navigateWithCartId(step: NavigationSteps, cartId: string): void {
    const slug = this.sessionStore.value.park?.urlSlug;

    if (!slug || step === NavigationSteps.Parks) {
      this.router.navigate(['parks']).then();
      return;
    }

    const route = this.route?.parent?.snapshot.url[2].path;
    if (route === step) {
      return;
    }

    this.router.navigate(['parks', this.sessionStore.value.park?.urlSlug, step],
      {queryParams: {cartId}})
      .then();
  }


  confirmHangout(booking: Booking): Observable<Booking> {
    return this.hangoutApiService.confirmHangout(booking)
      .pipe(tap(b => {
        this.setSubtotal(this.getSubtotal(b));
        this.bookingStore.next(b);
      }));
  }

  getPartyInvoice(cookieId: string): Observable<Booking> {
    return this.bookingApi.getBooking(cookieId);
  }

  // FIXME rename
  setBooking(booking: Booking): void {
    this.bookingStore.next(booking);
  }
  setUserWasLoggedInApplyingPromo(userWasLoggedInWhenApplyingPromo: boolean): void {
    this.userWasLoggedInApplyingPromo?.next(userWasLoggedInWhenApplyingPromo);
  }

  triggerBookingPromoModal(triggerBookingPromoModal: boolean): void {
    this.bookingPromoModalTrigger?.next(triggerBookingPromoModal);
  }
  setBookingPromoMessage(bookingPromoMessage: string): void {
    this.bookingPromoMessage?.next(bookingPromoMessage);
  }

  getQueryParams(step: NavigationSteps): any {
    const bookingQueryString = localStorage.getItem('bookingQueries');
    if (bookingQueryString && step === 'start') {
      return JSON.parse(bookingQueryString)['params'];
    } else {
      return null;
    }
  }
}
