import {Component, Inject, OnInit} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {concatMap, switchMap, take, tap} from 'rxjs/operators';
import {User} from '@unleashed/models/account';
import {AuthorizationService} from '@unleashed/services/account';
import {Booking, NavigationSteps, PartyInvoice} from '@unleashed/models/booking';
import {BookingSessionService} from '@unleashed/services/booking';
import {BookingInvoiceApiService, InvoiceApiService} from '@unleashed/api/booking';
import {BRAND_OPTIONS, BrandOptions} from '@unleashed/common/config';
import {PricePipe} from '@unleashed/common/pipes';

@Component({
  selector: 'ua-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {

  booking?: Booking;
  user?: User;
  detailLoaded = false;
  invoice$?: Observable<PartyInvoice>;
  depositAmount?: number;
  error?: number;
  isSpecialEvent?: boolean;
  brandId: number;
  showLocationPopup: boolean;
  triggerLocationPopup = false;
  triggerPromoConfirmationPopup = false;
  paymentProcessorVersion?: number;
  locationId?: string;
  // Promo
  promoCode = '';
  promoApplied = false;
  invalidPromoCode?: string;
  isNotLoggedInPromoApplied = false;

  currencyCode?: string;
  country?: string;
  conversionRate?: number;
  constructor(
    private sessionService: BookingSessionService,
    private invoiceApiService: InvoiceApiService,
    private bookingInvoiceApiService: BookingInvoiceApiService,
    private authService: AuthorizationService,
    @Inject(BRAND_OPTIONS) brandOptions: BrandOptions
  ) {
    this.brandId = brandOptions.brandId;
    this.showLocationPopup = brandOptions.showLocationPopup;
  }

  ngOnInit(): void {
    this.sessionService.userWasLoggedInApplyingPromo?.subscribe((value) => {
      this.isNotLoggedInPromoApplied = value;
    });

    combineLatest([
      this.sessionService.getConfig(),
      this.sessionService.getSession()
    ]).subscribe(([config, session]) => {
      this.currencyCode = session.park?.currencyCode;
      this.country = session.park?.address?.country;
      this.conversionRate = session.park?.currencyConversionRate;
      this.paymentProcessorVersion = (config.justifiSubAccountId || config.justifiCanadaSubAccountId) ?
        session?.park?.paymentProcessorVersion :
        1;
      this.locationId = session.park?.id;
    });

    combineLatest([
      this.authService.getUser(),
      this.sessionService.getBooking().pipe(take(1)),
    ]).pipe(
      switchMap(([user, booking]) => {
        this.user = user;
        if (this.user?.accountId) {
          return this.sessionService.upsertCart({
            ...booking,
            accountId: this.user?.accountId
          });
        }

        return of(booking);
      }),
      switchMap(booking => {
        this.booking = booking;
        return this.invoice$ = this.paymentProcessorVersion === 1
          ? this.invoiceApiService.getPreview(booking.cookieId || '')
          : this.bookingInvoiceApiService.getPreview(booking.cookieId || '', this.brandId, this.locationId);
      }),
      switchMap(inv => {
        this.detailLoaded = true;
        this.depositAmount = inv.deposit;
        this.isSpecialEvent = inv.isSpecialEvent;
        if (inv.promo) {
          this.promoCode = inv.promo.code ?? '';
          return this.invoiceApiService.confirmPromo(inv.promo.code ?? '', this.booking?.cookieId ?? '', this.booking?.accountId, true);
        }
        return of(undefined);
      })
    ).subscribe(promotion => {
      if (promotion === undefined) {
        return;
      }
      if (promotion && this.isNotLoggedInPromoApplied) {
        this.confirmAlterPromo();
        this.sessionService.triggerBookingPromoModal(true);
      }
      else if (promotion && !this.booking?.promotionCode) {
        this.triggerPromoConfirmationPopup = true;
      } else {
        this.alterPromo();
      }
    }, err => this.error = err.status);
  }

  back(): void {
    this.sessionService.navigateToStep(NavigationSteps.AddOns);
  }
  showLocationConfirmation(): void {
    // if show location popup is set to true in  brand options, then show the location confirmation popup
    if (this.showLocationPopup){
      this.triggerLocationPopup = true;
    } else {
      this.checkout();
    }
  }
  checkout(): void {
    if (!this.booking) {
      return;
    }

    this.sessionService.setBooking({
      ...this.booking,
      step: 6,
      depositAmount: this.depositAmount,
      isSpecialEvent: this.isSpecialEvent
    });
    if (!this.user?.loggedIn) {
      this.sessionService.navigateToSignUp(NavigationSteps.Checkout, NavigationSteps.Review);
    } else if (!this.user.address?.zipCode && !this.user.impersonated) {
      this.sessionService.navigateToAccountInfo(NavigationSteps.Checkout, true);
    } else {
      this.sessionService.navigateToStep(NavigationSteps.Checkout);
    }
  }
  confirmAlterPromo(): void{
    if (!this.promoCode) {
      this.invalidPromoCode = undefined;
      return;
    }
    this.invoiceApiService.confirmPromo(
       !this.isNotLoggedInPromoApplied && this.promoApplied ? '' : this.promoCode,
      this.booking?.cookieId ?? '',
      this.booking?.accountId,
      true
    ).subscribe(promotion => {
      if ( promotion !== null && promotion !== undefined) {
        if (promotion < 0) {

         this.sessionService.setBookingPromoMessage('Discounts may not be combined with any other offers. The current discount will be replaced with the smaller discount. Would you like to proceed?');
        } else if (promotion === 0) {
          this.sessionService.setBookingPromoMessage('Multiple discounts cannot exist, and the discounts are equal. Would you like to proceed?');
        } else {
          const pricePipe = new PricePipe();
          const formatted = pricePipe.transform(promotion * (this.conversionRate ?? 1), this.currencyCode);
          this.sessionService.setBookingPromoMessage(`Discounts may not be combined with any other offers. The current discount will be replaced, saving you an additional ${formatted}. Would you like to proceed?`);
        }
        this.triggerPromoConfirmationPopup = true;
      } else {
        this.alterPromo();
      }
    });
  }
  alterPromo(): void {
    if (!this.promoCode) {
      this.invalidPromoCode = undefined;
      return;
    }

    this.invoiceApiService.applyPromo(
      this.promoApplied ? '' : this.promoCode,
      this.booking?.cookieId ?? '',
      this.booking?.accountId,
      true
    ).subscribe(promotion => {
        this.invoice$ = (this.paymentProcessorVersion === 1
          ? this.invoiceApiService.getPreview(promotion.cookieId ?? '')
          : this.bookingInvoiceApiService.getPreview(promotion.cookieId || '', this.brandId, this.locationId))
          .pipe(tap(inv => {
            this.invalidPromoCode = !inv.promo && !this.promoApplied ? this.promoCode : undefined;
            this.promoCode = inv.promo?.code ?? '';
            this.promoApplied = !!inv.promo;
          }));
        if (promotion.code && !this.user?.loggedIn){
          this.sessionService.setUserWasLoggedInApplyingPromo(true);
        } else {
          this.sessionService.setUserWasLoggedInApplyingPromo(false);
        }
    });
  }
  handleLocationConfirmation(value: string): void {
    this.triggerLocationPopup = false;
    this.checkout();
  }
  handleRestart(): void {
    this.triggerLocationPopup = false;
    this.sessionService.removeCart();
    this.sessionService.navigateToStep(NavigationSteps.Parks);
  }
  handlePromoConfirmation(): void {
    this.triggerPromoConfirmationPopup = false;
    this.alterPromo();
  }
  handlePromoCancel(): void {
    this.triggerPromoConfirmationPopup = false;
    this.invoiceApiService.applyPromo(
       '',
      this.booking?.cookieId ?? '',
      this.booking?.accountId,
      true
    ).subscribe(promotion => {
        this.invoice$ = (this.paymentProcessorVersion === 1
          ? this.invoiceApiService.getPreview(promotion.cookieId ?? '')
          : this.bookingInvoiceApiService.getPreview(promotion.cookieId || '', this.brandId, this.locationId))
          .pipe(tap(inv => {
            this.promoCode = '';
            this.promoApplied = false;
            this.invalidPromoCode = undefined;
          }));
    });
  }
}
