import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {concatMap, switchMap, tap} from 'rxjs/operators';
import * as confetti from 'canvas-confetti';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { BRAND_OPTIONS, BrandOptions } from '@unleashed/common/config';
import { AuthorizationService } from '@unleashed/services/account';
import { Booking, NavigationSteps, PartyInvoice } from '@unleashed/models/booking';
import { BookingSessionService } from '@unleashed/services/booking';
import { BookingInvoiceApiService, InvoiceApiService } from '@unleashed/api/booking';

@Component({
  selector: 'ua-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  bookingId?: string;
  detailLoaded = false;
  invoice$ = new Observable<PartyInvoice>();
  booking: Booking = {
    parkId: '',
    requiresHandicap: false,
    guestsOfHonor: [],
    contactFirstName: '',
    contactLastName: '',
    phoneNumber: '',
    preferredDate: new Date(),
    preferredTimeId: 0,
    bookingItems: []
  };
  phoneNumber: string;
  guestsOfHonorNames?: string;
  width = 100;
  height = 100;
  launcher?: confetti.CreateTypes;
  error?: number;
  waiverLink?: string;
  partyStoreUrl: string;
  bookingConfirmation?: string;
  faCopy = faCopy;
  paymentProcessorVersion?: number;
  parkId?: string;
  brandId: number;
  subAccountId?: string;
  canadaSubAccountId?: string;


  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.width = (event?.target as Window).innerWidth;
    this.height = (event?.target as Window).innerHeight;
  }

  constructor(
    private sessionService: BookingSessionService,
    private authService: AuthorizationService,
    private invoiceApiService: InvoiceApiService,
    private bookingInvoiceApiService: BookingInvoiceApiService,
    @Inject(BRAND_OPTIONS) private brandOptions: BrandOptions
  ) {
    this.brandId = brandOptions.brandId;
    this.phoneNumber = brandOptions.phoneNumber;
    this.partyStoreUrl = window.origin;
  }

  ngOnInit(): void {
    this.width = window.innerWidth;
    this.height = window.innerHeight;

    this.sessionService.getConfig()
      .pipe(
        switchMap(config => {
          this.subAccountId = config.justifiSubAccountId;
          this.canadaSubAccountId = config.justifiCanadaSubAccountId;
          return this.sessionService.getSession();
        }),
        concatMap(session => {
          this.bookingId = session.bookingId;
          this.paymentProcessorVersion = (this.subAccountId || this.canadaSubAccountId) ? session?.park?.paymentProcessorVersion : 1;
          this.parkId = session.park?.id;
          this.phoneNumber = session.park?.enrolledGLT ?
            this.brandOptions.phoneNumber : session.park?.phoneNumber ?? '';
          return this.authService.getAccessToken();
        }))
      .subscribe(auth => {
        if (!auth || !this.bookingId) {
          this.sessionService.removeBooking();
          this.sessionService.navigateToStep(NavigationSteps.Start);
          return;
        }
        if (this.paymentProcessorVersion === 1){
          this.invoice$ = this.invoiceApiService.getInvoice(this.bookingId, auth)
          .pipe(tap(i => {
            this.booking.isSpecialEvent = i.isSpecialEvent;
            this.booking.selectedStartTime = i.startHour;
            this.booking.selectedEndTime = i.endHour;
            this.booking.selectedDate = new Date(i.date);
            this.bookingConfirmation = `${i.bookingId}-${i.confirmation}`;
            this.waiverLink = `${this.partyStoreUrl}/waiver?confirmation=${i.bookingId}-${i.confirmation}`;
            if (i.guests) {
              this.booking.guestsOfHonor = i.guests
                .map(g => (
                  {
                    firstName: g.firstName,
                    lastName: g.lastName,
                    dateOfBirth: new Date()
                  }));
              switch (i.guests.length) {
                case 1:
                  this.guestsOfHonorNames = `${i.guests[0].firstName}`;
                  break;
                case 2:
                  this.guestsOfHonorNames = `${i.guests[0].firstName} and ${i.guests[1].firstName}`;
                  break;
                default:
                  this.guestsOfHonorNames = '';
                  break;
              }
            }
          this.setDetailLoaded();
          }));
        } else {
          //Justifi
          this.invoice$ = this.bookingInvoiceApiService.getInvoice(this.bookingId, auth, this.brandId, this.parkId)
          .pipe(tap(i => {
            this.booking.isSpecialEvent = i.isSpecialEvent;
            this.booking.selectedStartTime = i.startHour;
            this.booking.selectedEndTime = i.endHour;
            this.booking.selectedDate = new Date(i.date);
            this.bookingConfirmation = `${i.bookingId}-${i.confirmation}`;
            this.waiverLink = `${this.partyStoreUrl}/waiver?confirmation=${i.bookingId}-${i.confirmation}`;
            if (i.guests) {
              this.booking.guestsOfHonor = i.guests
                .map(g => (
                  {
                    firstName: g.firstName,
                    lastName: g.lastName,
                    dateOfBirth: new Date()
                  }));
              switch (i.guests.length) {
                case 1:
                  this.guestsOfHonorNames = `${i.guests[0].firstName}`;
                  break;
                case 2:
                  this.guestsOfHonorNames = `${i.guests[0].firstName} and ${i.guests[1].firstName}`;
                  break;
                default:
                  this.guestsOfHonorNames = '';
                  break;
              }
            }
            this.setDetailLoaded();
          }));
        }
      }, err => this.error = err.status);
  }

  setDetailLoaded(): void {
    this.detailLoaded = true;
    setTimeout(() => {
      this.setupConfetti();
      this.makeConfetti();
    }, 500);
  }

  setupConfetti(): void {
    const canvas = document.getElementById('confetti') as HTMLCanvasElement;
    if (!canvas) {
      return;
    }
    this.launcher = confetti.create(canvas, {resize: true});
  }

  makeConfetti(): void {
    this.fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    this.fire(0.2, {
      spread: 60,
    });
    this.fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });
    this.fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });
    this.fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }

  fire(particleRatio: number, opts: confetti.Options): void {
    if (!this.launcher) {
      return;
    }
    const defaults = {
      origin: { y: 1 },
      resize: true
    };

    this.launcher({
      ...defaults,
      ...opts,
      particleCount: Math.floor(200 * particleRatio)})?.then();
  }

  copy(waiver: any): void {
    waiver.select();
    document.execCommand('copy');
    waiver.setSelectionRange(0, 0);
  }

}
